import './App.css';
import { useEffect, useState } from 'react';
import logo from './logo.png'
import { Toaster, toast } from 'sonner';
import { getDatabase, ref, onValue, set } from "firebase/database";
import app from './firebase';



function App() {
  const db = getDatabase(app);
  const [down, setDown] = useState(10);
  const [email, setEmail] = useState("");
  const data = {'balasrinivasan.sa.ece.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1SZTrJXYqrBTPArES-OKhIdgyeJYWiunW/view?usp=drive_link', 
  'arundas.k.cse.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1CePNnJbuNEZeI5FvW9tNidB94tsaZnBF/view?usp=drive_link', 
  'avinash.v.auto.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1KIy9gma-2ruL20_8Rt3HK64lOoOVcqS9/view?usp=drive_link',
  'devaprasanth.e.aiml.2022@snsct.org.pdf': 'https://drive.google.com/file/d/12Mu-6JPo5raJtmwgGGNvlrZJKJYCnpe2/view?usp=drive_link',
  'ashwin.jyothis@gmail.com.pdf': 'https://drive.google.com/file/d/190PvkYD-8RNAdaHV9H2C1I9oAXYYmfBb/view?usp=drive_link',
  'akhiles.p.cse.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1BrBXXy6TrdAM5pIJa8spfqCVywZoCVoh/view?usp=drive_link',
  'aravinth.t.cse.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1yQr9N6Upun9KT59y88DZeoCnZgd9UG9e/view?usp=drive_link',
  'akshaytir1011@gmail.com.pdf': 'https://drive.google.com/file/d/1BOCcbyIwgO8YPKicdD3seIhG9Aymos5V/view?usp=drive_link',
  'adhithya.p.s.cse.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1M3xMLUhoN-VhpHfGmcNNaSws7Kc33xUQ/view?usp=drive_link',
  'akshaya.s.cse.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1jL8-yeszYS_TeVWydAGuJ1xRmDNkbtS-/view?usp=drive_link', 
  'abivathan456789@gmail.com.pdf': 'https://drive.google.com/file/d/1I-ut9QbTusnhax_ysfAk0I44bfitFh3E/view?usp=drive_link', 
  'gokulme.p.mech.2020@snsct.org.pdf': 'https://drive.google.com/file/d/11rEZ1TbUwqgV8mq4cMWWNhzlW5UmBb3M/view?usp=drive_link', 
  'ajmalhas.s.mech.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1trTx9LgzrwDNgkyJYE_tGq1xIEE0QLTE/view?usp=drive_link', 
  'adityamdsa@gmail.com.pdf': 'https://drive.google.com/file/d/1Qwvvqul4FdafyWfrYulEXP5u9kgKsJ6G/view?usp=drive_link', 
  'arunav.v.cse.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1PLwxZLD3Juu_H56gDry8oaU3T8HSOgAI/view?usp=drive_link', 
  'barath.k.ece.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1zteuAbdX5o7-NAsA9QPZiRN3Hn7MkSqT/view?usp=drive_link', 
  'dinoojoel958@gmail.com.pdf': 'https://drive.google.com/file/d/1ZrLsFjMqcEphXCiBhBfm8VWui1GZTtOZ/view?usp=drive_link', 
  'adithyan.a.civil.2020@snsct.org.pdf': 'https://drive.google.com/file/d/16tB6wD9-dYaWlcefqwGaz58gffvKh0WK/view?usp=drive_link', 
  'chandru.r.civil.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1Qm1oe_Hf9Snb07KILHKYya0IHWqFDcmn/view?usp=drive_link', 
  'ashik.ms.cst.2021@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1qJoTtwdvGRXd2fdIjx037WKqnGsEvkNa/view?usp=drive_link', 
  'deepakkumar.mj.cst@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1z3OkCIGOE826UeUrWNgB6KfRZLN0HldQ/view?usp=drive_link', 
  'danushdanu1016@gmail.com.pdf': 'https://drive.google.com/file/d/16JwyeRdZdQg557it7vjh4l44buNl82Nc/view?usp=drive_link', 
  'gajendran.m.cst.2021@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1QTrS84H3S297fPDbWIUJZsPZourOCpuq/view?usp=drive_link', 
  'dharineesh.s.cst.2021@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1YILPTBcxCVjcBH1CpE29GSBiW68TllWF/view?usp=drive_link', 
  'anshu.21510@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1aAe4J2me2eaSlA_yOwnkUCU4mhIsU1nO/view?usp=drive_link', 
  'ankit.23512@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1MD8yGJLSjcF_PxKVsSPC-vP9iQ8Fft2z/view?usp=drive_link', 
  'dipakmandlik03@gmail.com.pdf': 'https://drive.google.com/file/d/1YBSW4lBUznUliO-hSTxiWo1-4aj4m3Pa/view?usp=drive_link', 
  'akashhaldar2112@gmail.com.pdf': 'https://drive.google.com/file/d/1c5zx5kFD8SWxM91BSzWho4kUyjiztyFD/view?usp=drive_link', 
  'anishadua2005@gmail.com.pdf': 'https://drive.google.com/file/d/153Yu-7ec11kI4HgiT-i8js9W-WmkeXSG/view?usp=drive_link', 
  'aloysjehwin.25ec@licet.ac.in.pdf': 'https://drive.google.com/file/d/1kR3B3KYQ7b1qaJfzupzBx46Izry2ZO-7/view?usp=drive_link', 
  '2k22csbs19@kiot.ac.in.pdf': 'https://drive.google.com/file/d/1SFp4jF5Aoz7emTXNpBxkp14JJcXmHhPH/view?usp=drive_link', 
  'akshatkishore1908@gmail.com.pdf': 'https://drive.google.com/file/d/1t-xatVwE85wVmLrDMKkrnDUTjOIsOfWX/view?usp=drive_link', 
  '99210041530@klu.ac.in.pdf': 'https://drive.google.com/file/d/1qOeS-uqUm4Pkh1hCseygB7cFG9agAYfo/view?usp=drive_link', 
  '99210041695@klu.ac.in.pdf': 'https://drive.google.com/file/d/1QVV4J2dWA08ysBzGLoWlTPYUHcx8YoHs/view?usp=drive_link', 
  '9921004168@klu.ac.in.pdf': 'https://drive.google.com/file/d/1sXHCFuhTKBZFnSHwIEzWtQm3v0S869y8/view?usp=drive_link', 
  '9921004093@klu.ac.in.pdf': 'https://drive.google.com/file/d/17hINeZ0dPeSojx7w4E9qJqxMz1gXen2b/view?usp=drive_link', 
  '99210041393@klu.ac.in.pdf': 'https://drive.google.com/file/d/1lWi5xvRTSjyTFprdcjCW3dtzZvajLrbR/view?usp=drive_link', 
  '9921004175@klu.ac.in.pdf': 'https://drive.google.com/file/d/1HMSu3eyCHUlnVG6Rw6qR3hZe8i8tXT1M/view?usp=drive_link', 
  'chandantrivedi1502@gmail.com.pdf': 'https://drive.google.com/file/d/1eEmYpf6DmN7f6Xx_0CjpfJRLaYqEtqUu/view?usp=drive_link', 
  '99210041164@klu.ac.in.pdf': 'https://drive.google.com/file/d/11GAf7QJIPRzatSex7QzoA8O4l_ki0lEt/view?usp=drive_link', 
  'gurjarsachin8508@gmail.com.pdf': 'https://drive.google.com/file/d/1ti6tzXXbbRVX2-Vtrh_na1XA3negNEv2/view?usp=drive_link', 
  'deepaky9560@gmail.com.pdf': 'https://drive.google.com/file/d/12rTFvv8akh9cSmYZpsFXn3jUMTGeGtG4/view?usp=drive_link', 
  'gargkrishna9354@gmail.com.pdf': 'https://drive.google.com/file/d/1tGxHyjSsqzOIMOurUfBQa4fjTVHiPiMp/view?usp=drive_link', 
  'dharuchintoo@gmail.com.pdf': 'https://drive.google.com/file/d/1Lgt-k9Q4j8oHolRJkMfGuqmpof4YTvKw/view?usp=drive_link', 
  'goyalsaksham40@gmail.com.pdf': 'https://drive.google.com/file/d/1DHO5J3TtOWvVLsCtMMxgYjj9hCZz-nXV/view?usp=drive_link', 
  'goyalsaksham0@gmail.com.pdf': 'https://drive.google.com/file/d/1MUAd2ELNpg94kduDCh1LSqMF_9oaKgHY/view?usp=drive_link', 
  'ayushabhinav812@gmail.com.pdf': 'https://drive.google.com/file/d/1Vcug2QszSroXdsXciWeTa0QwCNW1k3zB/view?usp=drive_link', 
  'aryansaraf7@gmail.com.pdf': 'https://drive.google.com/file/d/1UJK34BlFQc7nBmkl3XzFJL_0ATyTIA_6/view?usp=drive_link', 
  'bbudhiraja16@gmail.com.pdf': 'https://drive.google.com/file/d/10dszEMK912PCturtnlpBq8N78jArP1rx/view?usp=drive_link', 
  '20bcs6795@cuchd.in.pdf': 'https://drive.google.com/file/d/14rA6A1qFbKbBySN0t2ceZ6pTC2ouczDK/view?usp=drive_link', 
  'abhisheklakra385@gmail.com.pdf': 'https://drive.google.com/file/d/1BVulRnqqYeLM1cDD-gIHZBMJOeIreEQQ/view?usp=drive_link', 
  'deeptanshurai20@gmail.com.pdf': 'https://drive.google.com/file/d/16v2PXctHGobtOthyCKzCuwMbtpkzpB3i/view?usp=drive_link', 
  'gautamchhavi13@gmail.com.pdf': 'https://drive.google.com/file/d/1PlzOzfMmLDL7djvWZtCjmMKwKsiA1XhL/view?usp=drive_link', 
  '2709priyanshu@gmail.com.pdf': 'https://drive.google.com/file/d/1uqUp4ikNrEGuyKQCNjOgupoty6VYCSh9/view?usp=drive_link', 
  'anirudhmehra2005@gmail.com.pdf': 'https://drive.google.com/file/d/11K6SxtVpjR7VSqpm1o-LpXex5Wn491xI/view?usp=drive_link', 
  'abhijeetvardhan9128@gmail.com.pdf': 'https://drive.google.com/file/d/1w4i4ERKqMhqnKBITu8Fxxvv9hxWgAEWf/view?usp=drive_link', 
  'aryanpahuja1421@gmail.com.pdf': 'https://drive.google.com/file/d/1qWbwe2r_bSm_LYciyzXY5_gwPUB8v1w8/view?usp=drive_link', 
  'chaudharyrajan387@gmail.com.pdf': 'https://drive.google.com/file/d/1foN7CqUiRxEd2xWG7fejB5ipP_FxmXJw/view?usp=drive_link', 
  'alokvns48@gmail.com.pdf': 'https://drive.google.com/file/d/1Weq7fTinubkyIxwUjETvROfT9cCgx8DC/view?usp=drive_link', 
  'ajaysinghbisht195@gmail.com.pdf': 'https://drive.google.com/file/d/1Rel-CRzRcMSYem_MabspYx8gsnDnn8RV/view?usp=drive_link', 
  'baranwalrajesh573@gmail.com.pdf': 'https://drive.google.com/file/d/1qtuPZdw7JxSsZ2mUs5epPBuLVARR6mAN/view?usp=drive_link', 
  'amirbxr2003@gmail.com.pdf': 'https://drive.google.com/file/d/1tEEOzrQqksK9fSi6-CRlRsSbSbNsaCg6/view?usp=drive_link', 
  'aggarwalmukul2194@gmail.com.pdf': 'https://drive.google.com/file/d/1ed--P-CLyNV3z_2xUPOSipYA9NHHuOdR/view?usp=drive_link', 
  'arham321ansari@gmail.com.pdf': 'https://drive.google.com/file/d/1sDLXH9JJgMHNj-Is3Ivo_k4CsxRALhIb/view?usp=drive_link', 
  'arham9cgpa@gmail.com.pdf': 'https://drive.google.com/file/d/1bWmsvgoTP0PHajxZtFu57xrKH7Xgrpuz/view?usp=drive_link', 
  'asifuddeshhya@gmail.com.pdf': 'https://drive.google.com/file/d/1gZJNT8MIWYZLCHwixsv9ziV2KbJS0EvS/view?usp=drive_link', 
  'bhadauriyayash712@gmail.com.pdf': 'https://drive.google.com/file/d/1KJWrlrq8Vm_Fi6s2KJbBQ84Ip2sZvB67/view?usp=drive_link', 
  '22103103@mail.jiit.ac.in.pdf': 'https://drive.google.com/file/d/1LXpaIhDNa3xN0OI1bNM-4BzPO0OAd_6d/view?usp=drive_link', 
  '22103116@mail.jiit.ac.in.pdf': 'https://drive.google.com/file/d/1X7nZAtj2nJNTWHjVpgAWLCpYPUmjOLe7/view?usp=drive_link', 
  'akshatsinghdehradun2005@gmail.com.pdf': 'https://drive.google.com/file/d/1ENgM4k4EN0PkhOwoF5bx2YbGHDF1mLB1/view?usp=drive_link', 
  'aashishn065@gmail.com.pdf': 'https://drive.google.com/file/d/1-T-jS9QpgNFWOwQeDPl79fMiElJMEchp/view?usp=drive_link', 
  'anubrat23@gmail.com.pdf': 'https://drive.google.com/file/d/1s_KZ1nVmli1gsUjdsiLbjZ5hcbXWM72E/view?usp=drive_link', 
  'dwivediankush30@gmail.com.pdf': 'https://drive.google.com/file/d/12mRL0f6-izptDSU5O_4ZqfukDY4YrO00/view?usp=drive_link', 
  'adit10022004@gmail.com.pdf': 'https://drive.google.com/file/d/1HvxtczqFeTWr4j_m2Fqh1eJwQ0JHQHLr/view?usp=drive_link', 
  'ansh172003@gmail.com.pdf': 'https://drive.google.com/file/d/1xSIrLzHXwdWEBtqg9AYsoK4T6P-qt7dg/view?usp=drive_link', 
  '230160226034.manish@gdgu.org.pdf': 'https://drive.google.com/file/d/1lnIDgbykBPwaFdTlF5vbj27fGV5fUhoi/view?usp=drive_link', 
  'cyberespionage1o1@gmail.com.pdf': 'https://drive.google.com/file/d/1NLd6bWJSOPu__nIBJ2laAtBViacaSRGr/view?usp=drive_link', 
  'amaan.haddiya1707@gmail.com.pdf': 'https://drive.google.com/file/d/1QKkHhofdnELW4J0LNV7FvwCmWqdcCxCs/view?usp=drive_link', 
  'dipanshukansal2004@gmail.com.pdf': 'https://drive.google.com/file/d/16j3WItp2NgjYuQHuy6ZD_RHd-8uOjfsi/view?usp=drive_link', 
  '230160223083.saterjeet@gdgu.org.pdf': 'https://drive.google.com/file/d/1IJi4fZcZLvTv4PPmSeHTY5AFm-x4YNzK/view?usp=drive_link', 
  'bishtparth227@gmail.com.pdf': 'https://drive.google.com/file/d/1Y1s2OJ8R-14GaF_y-A2-RbQaVhiIZeJZ/view?usp=drive_link', 
  'ashish6387704964@gmail.com.pdf': 'https://drive.google.com/file/d/1wncERFRgfKvG_tyvwUwqvgDxAho_tSjr/view?usp=drive_link', 
  'abhishekbharadwaj845@gmail.com.pdf': 'https://drive.google.com/file/d/18Y3oqff9n63TPBp_GNeuK_XhLvfnQk-p/view?usp=drive_link', 
  'aaryansharma1097@gmail.com.pdf': 'https://drive.google.com/file/d/1Hsahc2fdqrxUvyPQYcMHlUeaOxPodSPH/view?usp=drive_link', 
  '22bet10369@cuchd.in.pdf': 'https://drive.google.com/file/d/1idG1H2BtE7n0xgaXPMdxiPFh94evGMZJ/view?usp=drive_link', 
  'ashishraghav913@gmail.com.pdf': 'https://drive.google.com/file/d/1W5rekpCYZ1OfHSyCom0_AOLmfXcXvHRp/view?usp=drive_link', 
  'gauravchahar397@gmail.com.pdf': 'https://drive.google.com/file/d/1Yiv6_UswIRqwGq6YAFj3WSxx-lahfJEP/view?usp=drive_link', 
  'atharvgupta098@gmail.com.pdf': 'https://drive.google.com/file/d/1iqBExX1GaDiykvmhrU3uY2RjUruQDR91/view?usp=drive_link', 
  'akanshjain303@gmail.com.pdf': 'https://drive.google.com/file/d/1Enw5FZJpr01bWiO-JkilTU6hZtqOe_x6/view?usp=drive_link', 
  'coderjaskirat@gmail.com.pdf': 'https://drive.google.com/file/d/1yT7sAKDhVlVk1Xk0RN_9GZevekzj-Oku/view?usp=drive_link', 
  'asbankit8@gmail.com.pdf': 'https://drive.google.com/file/d/1saEVTdGR8ZQZUd6j9SHQu8ExVWOZWhi7/view?usp=drive_link', 
  'chirag1192004@gmail.com.pdf': 'https://drive.google.com/file/d/1uXWd16ReCeegsJrQrG7ZovDJulNWH5vs/view?usp=drive_link', 
  'gauranshgoel04@gmail.com.pdf': 'https://drive.google.com/file/d/1B6I7Rr6gOxs4gw0hZW7kpfpvLpaW4570/view?usp=drive_link', 
  'dhruv18gaba@gmail.com.pdf': 'https://drive.google.com/file/d/1AsqfKDgcd2p3Y6nabRZGmG0S0vSbYzwm/view?usp=drive_link', 
  'ayush21153019@akgec.ac.in.pdf': 'https://drive.google.com/file/d/1k7Mq0ySrQkXFYfWec9eBfjWIgWqnLqeU/view?usp=drive_link', 
  'adityasrivastava807@gmail.com.pdf': 'https://drive.google.com/file/d/1NjuiCHpdFA6XHO36MgdzDlockGuT0ivt/view?usp=drive_link', 
  'anikadaga.31@gmail.com.pdf': 'https://drive.google.com/file/d/1RwoinhhnXzqAEzzojpckCDoDesD5IVDH/view?usp=drive_link', 
  '230160226005.anshika@gdgu.org.pdf': 'https://drive.google.com/file/d/1K3evTPqYMz3BErzIXMaE4M8pfDvN9Ojj/view?usp=drive_link', 
  'chhavigautam171@gmail.com.pdf': 'https://drive.google.com/file/d/11W7QFYUO8hFl_vBKQ3GcG1DANsEzWHlR/view?usp=drive_link', 
  'aanyasharma2408@gmail.com.pdf': 'https://drive.google.com/file/d/1cRnXQwcBp0Auw_ITPnCHOMHMNBeT-DHU/view?usp=drive_link', 
  'gyash7487@gmail.com.pdf': 'https://drive.google.com/file/d/1PnN71fhIGGXN6VOHOh2HMRt4ggCD7DH4/view?usp=drive_link', 
  'hamanth.s.mech.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1tsDnRYNflPDPccqQsrJyad2V13jfgViS/view?usp=drive_link', 
  'hardikgupta068@gmail.com.pdf': 'https://drive.google.com/file/d/1WFuNNa9td8XHsGdtEjG2dlBJ-K8PqKK1/view?usp=drive_link', 
  'hardikmaheshwari828@gmail.com.pdf': 'https://drive.google.com/file/d/1AfPBBxTBRVBuafAJnzYOwkESYHdnmVej/view?usp=drive_link', 
  'hari1355499@gmail.com.pdf': 'https://drive.google.com/file/d/1kUXIrft6SQHm-bMeF-3HHTG1kt5vlFhm/view?usp=drive_link', 
  'hariharaasai2005@gmail.com.pdf': 'https://drive.google.com/file/d/1_k-6_DwNtd3Bw_MEZYMqzvxPKxLwey52/view?usp=drive_link', 
  'harinandhinir072004@gmail.com.pdf': 'https://drive.google.com/file/d/1FTe5Dvb-N0Y-7ynyqRtALgC1PQW6Npw_/view?usp=drive_link', 
  'harini.s.bme.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1cg6BbWQgOFCV-UFZ-QcDx3tJt-Z7XC8k/view?usp=drive_link', 
  'harish.jv.auto.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1RQEhne-OVHBA52IdJg91liknncN7-2Lk/view?usp=drive_link', 
  'harshdeepgupta2002@gmail.com.pdf': 'https://drive.google.com/file/d/1VaJOgq9luZpuW6VbjQ3H9UjU9nDQsUUZ/view?usp=drive_link', 
  'harshilsangani07@gmail.com.pdf': 'https://drive.google.com/file/d/1crF57qjb6bVvsbwwTmLcpfuziOpvas3H/view?usp=drive_link', 
  'harshit8377@gmail.com.pdf': 'https://drive.google.com/file/d/1gkKoDDi7Rgsmtn8tMXwsAZoAYsYaDpDn/view?usp=drive_link', 
  'harshkumar4756@gmail.com.pdf': 'https://drive.google.com/file/d/1-UjihTr1_suoI8ug1KQiIqFd9N81Il6u/view?usp=drive_link', 
  'harshsharma131610@gmail.com.pdf': 'https://drive.google.com/file/d/1qdP0Scf912XwkROdmJHCK835mmO0i1kx/view?usp=drive_link', 
  'harvansh.086@gmail.com.pdf': 'https://drive.google.com/file/d/1WEghtqQXj0n0h08V94A4u19blOXvRkr_/view?usp=drive_link', 
  'hemanthdokkari99@gmail.com.pdf': 'https://drive.google.com/file/d/1fKW1UEvvmO3Y2wfF_F1yqpZznvYyUV7E/view?usp=drive_link', 
  'hepzibahjerin5@gmail.com.pdf': 'https://drive.google.com/file/d/1o6w8-n513MsslbX8q2hfIWdnLKwYGgSQ/view?usp=drive_link', 
  'hilalahmad4703@gmail.com.pdf': 'https://drive.google.com/file/d/1OfHJg9gN9hTljO0tCAXJStVDhFY0P5NK/view?usp=drive_link', 
  'himanshu.singh.250803@gmail.com.pdf': 'https://drive.google.com/file/d/1Xkhd5yiIarJBqH4bda8ngNzIi6QlvaEX/view?usp=drive_link', 
  'himanshu20032017@gmail.com.pdf': 'https://drive.google.com/file/d/1YUA5GKjgk4BKsYBv5ymoa9LFyN3HoLwO/view?usp=drive_link', 
  'hkohli799@gmail.com.pdf': 'https://drive.google.com/file/d/1xEcZiGZychEVG_9sthQn0meh2JGJrstH/view?usp=drive_link', 
  'hsiskskgayua@gmail.vom.pdf': 'https://drive.google.com/file/d/1Y2Jq3PRn2NVHREKa31RQBotbZrPqJaLd/view?usp=drive_link', 
  'igivekissesoncheeks@gmail.com.pdf': 'https://drive.google.com/file/d/1_DLU9aHfmjswrUTfEJ6Qd3auOpC21BSV/view?usp=drive_link', 
  'imujjwaltiwari@gmail.com.pdf': 'https://drive.google.com/file/d/1ifynJ-f3XiMrpvkVOaNanofKzxNz7Q1T/view?usp=drive_link', 
  'infuisgoated@gmail.com.pdf': 'https://drive.google.com/file/d/1KNe032HGDp5OwCgKop1HhvN766bJU80Y/view?usp=drive_link', 
  'ishikagarg767@gmail.com.pdf': 'https://drive.google.com/file/d/1mfBAwO-MTd_bXjyX6sNSjQ3MJGmTDDV3/view?usp=drive_link', 
  'ishimistic12@gmail.com.pdf': 'https://drive.google.com/file/d/10IAG1SmWZNpEz9yWu5B_9dJ_4zTUT0Eg/view?usp=drive_link', 
  'ishu3662@gmail.com.pdf': 'https://drive.google.com/file/d/1a24QJZR3dq9QvzYo1cwn2RDKOCVgH0vP/view?usp=drive_link', 
  'jaigautam22062003@gmail.com.pdf': 'https://drive.google.com/file/d/1-pZ4VqE06ZJ2dHyo8YYZojCh3joTs2vy/view?usp=drive_link', 
  'jain.anubhav.2001@gmail.com.pdf': 'https://drive.google.com/file/d/1lzlvVmdbleDACHPcia1gN_8y8W8yRg6U/view?usp=drive_link', 
  'jaiprakasham01@gmail.com.pdf': 'https://drive.google.com/file/d/1O9lH-w7pCDsSdv_wXrjpqAVp-Kog9lWh/view?usp=drive_link', 
  'jatin_23dmba54@dtu.ac.in.pdf': 'https://drive.google.com/file/d/1D7xQdBoTOC4H5JlDCZDplkENpByPLxBn/view?usp=drive_link', 
  'jatingera2004@gmail.com.pdf': 'https://drive.google.com/file/d/1Tv__0o2cKkZjf42UdLZQCHOg_Zv-3eHN/view?usp=drive_link', 
  'jatinjatinth@gmail.com.pdf': 'https://drive.google.com/file/d/1UpBLn_0jPmIuzcPFnIgS8-HHZqUDVWYx/view?usp=drive_link', 
  'kamali.k.agri.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1FoVm-fXK2UbH6otHW6zToxbT_Y9HDR1J/view?usp=drive_link', 
  'kanikakhurana595@gmail.com.pdf': 'https://drive.google.com/file/d/1h9HqPGgAzdvW6D50ZPX21Zx9uJx3xfIz/view?usp=drive_link', 
  'karthiga.r.civil.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1WzSii24fKhoVw5E-FKL-q4uZYxUREBcP/view?usp=drive_link', 
  'kartikdeepgautam101@gmail.com.pdf': 'https://drive.google.com/file/d/17RLct3ocj_2WrwSytL3igz9i844hpSoC/view?usp=drive_link', 
  'kashutosh14243@gmail.com.pdf': 'https://drive.google.com/file/d/1ibQpL1ZwnafS6AwBgKjt8WKz2YNaioEj/view?usp=drive_link', 
  'kashyapanrg2112@gmail.com.pdf': 'https://drive.google.com/file/d/1My7FJCKsYzOouCtPI651-3DuS1YYHBfE/view?usp=drive_link', 
  'keshav14kumar2004@gmail.com.pdf': 'https://drive.google.com/file/d/1dMmhUfD7-YJipd2co40KLOABjde4-1x9/view?usp=drive_link', 
  'khanarmanahmad0@gmail.com.pdf': 'https://drive.google.com/file/d/1grli9JML03oumEv8SUmSgUkUqU1FnOfA/view?usp=drive_link', 
  'khushi.k1804@gmail.com.pdf': 'https://drive.google.com/file/d/1MxNs-ycaLXjY0oHfBofVGn0dWyZWi6JW/view?usp=drive_link', 
  'khushi.kt1804@gmail.com.pdf': 'https://drive.google.com/file/d/1R32g3j_iVKNkI4XEbNvLOijyWtePdzYd/view?usp=drive_link', 
  'khushipariminda@gmail.com.pdf': 'https://drive.google.com/file/d/15IgnoaCGQlga0Vgb2f_dKlLDgiYFOtTv/view?usp=drive_link', 
  'kirtichauhanofficial123@gmail.com.pdf': 'https://drive.google.com/file/d/1L-wLzxJ9X97_EbgJO-8sfp1XmN6v866C/view?usp=drive_link', 
  'kirtigupta0425@gmail.com.pdf': 'https://drive.google.com/file/d/1Jo9lN3yz6RfHcGc6Ns441RQCTKh17jue/view?usp=drive_link', 
  'komal093btcse22@igdtuw.ac.in.pdf': 'https://drive.google.com/file/d/1554do6R3UuJvGTSLzyCd1T0lNXKdPz7O/view?usp=drive_link', 
  'krishnagarg262@gmail.com.pdf': 'https://drive.google.com/file/d/1_A4xI39Das3iNmozIPBtbLo8M_noee3c/view?usp=drive_link', 
  'kshitij.22363@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1bbOvwbPaAwOdhWab8PoujwMzxQWyX8Kq/view?usp=drive_link', 
  'kshitizmanral@hotmail.com.pdf': 'https://drive.google.com/file/d/1u570AR8Y-Py0UYdk6WtB-rVZuhg9LdIK/view?usp=drive_link', 
  'kumar.ak.aryan2@gmail.com.pdf': 'https://drive.google.com/file/d/1eNeOu3p5JySG9cgzNPQht2gcpl_f1IQv/view?usp=drive_link', 
  'kumar.ashish8397@gmail.com.pdf': 'https://drive.google.com/file/d/1iZtLgA5ACJgkOHmCAuJoJ6F-hIBjSQMw/view?usp=drive_link', 
  'kunalbdtucse@gmail.com.pdf': 'https://drive.google.com/file/d/1Ky7WTRloLdqq6d47oB7V1Cx4o8pLmTGs/view?usp=drive_link', 
  'kunaldtucse@gmail.com.pdf': 'https://drive.google.com/file/d/14DJG6XnwusIetCd9KZoS8Zw0Ys1jEvbl/view?usp=drive_link', 
  'lakshgumber42@gmail.com.pdf': 'https://drive.google.com/file/d/11PWbJZB2pyom-FStHFX-OzzCZgQtc1sX/view?usp=drive_link', 
  'm.aksharamurali03@gmail.com.pdf': 'https://drive.google.com/file/d/1VqOQaOjDDFn83wAqHMqXKhLBbMsx3j9k/view?usp=drive_link', 
  'ma7543@dseu.ac.in.pdf': 'https://drive.google.com/file/d/1xbv2JQkCJinlDlG0qMdyGoTvnj8PA468/view?usp=drive_link', 
  'madhavvsbprog@gmail.com.pdf': 'https://drive.google.com/file/d/1ejz0OH7x5v7JCqryzEqKFB5ar0eA8B2v/view?usp=drive_link', 
  'mail.vimun@gmail.com.pdf': 'https://drive.google.com/file/d/1beL4vgznk2nF6pJjyx-ObtmigqD8e5Uz/view?usp=drive_link', 
  'mailsahil202@gmail.com.pdf': 'https://drive.google.com/file/d/1RQHRZh_5rsPKmTsP0lIgFyjvQxP8K2VF/view?usp=drive_link', 
  'manage.prodev2002@gmail.com.pdf': 'https://drive.google.com/file/d/1i1LJpGfesvoVbFlyxJ7sr_CS1lnROBY3/view?usp=drive_link', 
  'manankochhar10@gmail.com.pdf': 'https://drive.google.com/file/d/10fq_s6R4Zv6M7gTltyYf1OlGAsJlO1vS/view?usp=drive_link', 
  'manojkumar.l.eee.2021@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1Y-vNT_Q43VJzsUd2Y--lnUKvN85q6138/view?usp=drive_link', 
  'masoodsaqib00@gmail.com.pdf': 'https://drive.google.com/file/d/1Hw5yJc6Jd-IaC-4USIrBxcVpO8vu5I8C/view?usp=drive_link', 
  'mathesh.m.mech.2022@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1vTtr4PJvluf9nsfKV4dLCXOgrAbcIvkV/view?usp=drive_link', 
  'mayankhooda590@gmail.com.pdf': 'https://drive.google.com/file/d/1_mAcU8njSYNEvTLiK5S2yd57jXh1MHEL/view?usp=drive_link', 
  'mdasifjamilipu@gmail.com.pdf': 'https://drive.google.com/file/d/1xHIugzdxRsf16VOX___XH1qn2riljrwm/view?usp=drive_link', 
  'mdasifraja23@gmail.com.pdf': 'https://drive.google.com/file/d/1akjxJs88lRHc38TnMeYnTz1vw_HQpRnw/view?usp=drive_link', 
  'meetmandhan672@gmail.com.pdf': 'https://drive.google.com/file/d/1z9nIQ_H20ZNQ9GdQ1ppsnf6v27CIGKOx/view?usp=drive_link', 
  'mishravinesh18@gmail.com.pdf': 'https://drive.google.com/file/d/14Ppn2DNGshMpPzNgrK78ll6Qspa7lR_6/view?usp=drive_link', 
  'mithun.k.mct.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1TBbesdy8pIPAKw44FMrmBoFal0Zag6eo/view?usp=drive_link', 
  'mj32771@gmail.com.pdf': 'https://drive.google.com/file/d/14LEWNa3yFiCJCMDDQVqhBrJK8RUBb1s-/view?usp=drive_link', 
  'mms23_017iom@met.edu.pdf': 'https://drive.google.com/file/d/10ICddd4E5dfvUDEC7JM1Cs1m2dv3ZXnZ/view?usp=drive_link', 
  'mohd.2226cseai1170@kiet.edu.pdf': 'https://drive.google.com/file/d/1oVaYUGhjgR5TwtlKNyX1MW_mQ6HgJ2UG/view?usp=drive_link', 
  'mohdakif8567@gmail.com.pdf': 'https://drive.google.com/file/d/19_jaIGYrA0K73ntmqBOAN1knUYaXb4N1/view?usp=drive_link', 
  'mohdharisari@gmail.com.pdf': 'https://drive.google.com/file/d/1fXensrxqZlIIgYDaH3lro8UZudnF9xk5/view?usp=drive_link', 
  'mohsinali6779@gmail.com.pdf': 'https://drive.google.com/file/d/1dQxk0o-6zZNWZ3A36E5_4rmnwzI4QqlG/view?usp=drive_link', 
  'mrvikasofficial01@gmail.com.pdf': 'https://drive.google.com/file/d/1MGFiirCNQlS9mfJTKuBHqCZUqraV59B8/view?usp=drive_link', 
  'muazaqdassh@gmail.com.pdf': 'https://drive.google.com/file/d/1PPhFc1esaYzqSUIPykgOUy_nNv2rgORy/view?usp=drive_link', 
  'muskansharma20012003@gmail.com.pdf': 'https://drive.google.com/file/d/1sdogKNkp__-bh8O0MhtkndIDKBEQRF_V/view?usp=drive_link', 
  'naheelm62@gmail.com.pdf': 'https://drive.google.com/file/d/1eT7tdVEnbD_ikSUeOI_VeHYl8nzczCkc/view?usp=drive_link', 
  'naseemq063@gmail.com.pdf': 'https://drive.google.com/file/d/19-BT57L7V20jDIvZxZzE-7IPshCzNCd2/view?usp=drive_link', 
  'navneetkumar2004@icloud.com.pdf': 'https://drive.google.com/file/d/1y2L--jtmemPxvSyOJHteB74dlWM9VuZq/view?usp=drive_link', 
  'navr90210@gmail.com.pdf': 'https://drive.google.com/file/d/1yUW1SlfXwj3mAh5r-YJbc_aJF1PaEtfh/view?usp=drive_link', 
  'nayakgautam135@gmail.com.pdf': 'https://drive.google.com/file/d/1zO5QYqrY5yf8G6612Fmu6qm5xA04pumq/view?usp=drive_link', 
  'niharbansal2003@gmail.com.pdf': 'https://drive.google.com/file/d/1zTeztAHU1nSn5G2BKjuKbEKYfdeuz8OS/view?usp=drive_link', 
  'nikhilchauhan1128@gmail.com.pdf': 'https://drive.google.com/file/d/1DBRTK-u0PVf_KHBLRTuBQhgjX9XlLvHG/view?usp=drive_link', 
  'nikhilkr2604@gmail.com.pdf': 'https://drive.google.com/file/d/1IkyMm3rtVF0xcbDQxrJ-2H69O8xHnQRw/view?usp=drive_link', 
  'nikhilraikwar846@gmail.com.pdf': 'https://drive.google.com/file/d/1AJlpVGmuEtu85mTo4OoaXvPubeUNCQtG/view?usp=drive_link', 
  'nikhilsinghal1704@gmail.com.pdf': 'https://drive.google.com/file/d/1mGUxxNMAcfOj4q_ALUA9lv03J6ZjRMEC/view?usp=drive_link', 
  'nikitanautiyal109@gmail.com.pdf': 'https://drive.google.com/file/d/1Gtx_WbRHshbUX5ex40zRBroShxhKOKYC/view?usp=drive_link', 
  'nikunj9180@gmail.com.pdf': 'https://drive.google.com/file/d/1L-xdqhnidxgJAaR5gZqVjs2onyFse4Bp/view?usp=drive_link', 
  'nipungoel346@gmail.com.pdf': 'https://drive.google.com/file/d/1jjBOF3QkkCYofJR05PXQ0XfP-wj7WwkP/view?usp=drive_link', 
  'nisha257372@gmail.com.pdf': 'https://drive.google.com/file/d/1GdczmEQbiuhUxFuiHWVFeKVUgutzlPKu/view?usp=drive_link', 
  'nishthabhateja77@gmail.com.pdf': 'https://drive.google.com/file/d/1QaYh0rBlVIdtawDHL8Q5j4nqp15ofRjo/view?usp=drive_link', 
  'nishupathak208@gmail.com.pdf': 'https://drive.google.com/file/d/1IQazZ2Tt_n57_B5QlljIlJUQtHZwrXXc/view?usp=drive_link', 
  'nitheesh.s.ece.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1EotLMVkkAAYeKYuXrv3Gh6lATjhUizWk/view?usp=drive_link', 
  'nitishchauhan9090@gmail.com.pdf': 'https://drive.google.com/file/d/1PoWfE5wdCPFZROz4-CyuYhZVvBdk96-C/view?usp=drive_link', 
  'nivas.l.mech.2022@snsce.ac.in.pdf': 'https://drive.google.com/file/d/13Tqbd1pmZTAzuEMWfOKpk-lIB-fpVexy/view?usp=drive_link', 
  'nk2473@dseu.ac.in.pdf': 'https://drive.google.com/file/d/1BZ7zwFTGi22IBsoCtti844TpQngVCTvv/view?usp=drive_link', 
  'official.pariag06@gmail.com.pdf': 'https://drive.google.com/file/d/1u92X38__c69c7VdNXuCA0EVjxImlz3uK/view?usp=drive_link', 
  'p23rishikasoni@iimidr.ac.in.pdf': 'https://drive.google.com/file/d/1iDaWxJDQ33NjLEC5cCh-G-iYvHKjgQ79/view?usp=drive_link', 
  'paarthaggarwal418@gmail.com.pdf': 'https://drive.google.com/file/d/1J1oOkYBdsArtOqo7JSZaRSz3Pr_t80dI/view?usp=drive_link', 
  'paavni129btcse22@igdtuw.ac.in.pdf': 'https://drive.google.com/file/d/1WaL0arnpS5NM7820Dd3gwWDpjcls_Nkz/view?usp=drive_link', 
  'pald42450@gmail.com.pdf': 'https://drive.google.com/file/d/1QaA97pqAYWWHorm1VF0iUeAKtKVXVE6Y/view?usp=drive_link', 
  'pandeyvasu152428@gmail.com.pdf': 'https://drive.google.com/file/d/1ox-REeiGzH4A4GNG_z6X6duPmNLXlw8c/view?usp=drive_link', 
  'parameshwaran.v.cst@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1KKwbuytgHY_qB1YRs4xlq3Ro8LInqhYC/view?usp=drive_link', 
  'parasrathi0105@gmail.com.pdf': 'https://drive.google.com/file/d/1-MFCSXjXh70YeCG4wjeURPRnflnAzM-Y/view?usp=drive_link', 
  'parth615gupta@gmail.com.pdf': 'https://drive.google.com/file/d/13exqO5zG0RIZCxvtmgS_OmKyErd8muhA/view?usp=drive_link', 
  'parthsikri.28@gmail.com.pdf': 'https://drive.google.com/file/d/1wecc-U5__rtKTNhIn6iDs0V6O45rDmfM/view?usp=drive_link', 
  'parthsikri.31@gmail.com.pdf': 'https://drive.google.com/file/d/1nH1IUlvYds9UbK7faI6F-n0yjedJHs7M/view?usp=drive_link', 
  'patelshikhar43@gmail.com.pdf': 'https://drive.google.com/file/d/1Tc24HmAkpT7BVqQNBfVFu5zkNS9TYenn/view?usp=drive_link', 
  'paul537456@gmail.com.pdf': 'https://drive.google.com/file/d/1mgdTb3Yzb4ZyK0i-FEFx9IdXty8HJLLZ/view?usp=drive_link', 
  'pb7369@gmail.com.pdf': 'https://drive.google.com/file/d/1kj7g-6IL-ooHUiuzprTHKta4rGG58GBL/view?usp=drive_link', 
  'pbhardwaj15205@gmail.com.pdf': 'https://drive.google.com/file/d/1CVoe1fwuXD1EkQQkEKcOB2LpHGtXlTWj/view?usp=drive_link', 
  'pendurthipavansai@gmail.com.pdf': 'https://drive.google.com/file/d/1hxh2624QOH7wVzkMefZPNWtbp1Kk_6IA/view?usp=drive_link', 
  'pewdipie1947@gmail.com.pdf': 'https://drive.google.com/file/d/1vIjldRFGpApMJJP23QzC6TCbQg4jVgz9/view?usp=drive_link', 
  'piyush.bhawsar1@gmail.com.pdf': 'https://drive.google.com/file/d/1BBCUPJI5wyppRB0c9cNxF8KZQ0_mupkc/view?usp=drive_link', 
  'prabal.gupta2003@gmail.com.pdf': 'https://drive.google.com/file/d/17NKMYtQoGI_KlFZHt8i1vznonQgxAfdo/view?usp=drive_link', 
  'PrabhatmishrA7131@gmail.com.pdf': 'https://drive.google.com/file/d/1wqevCFqKR64_rfkBnODrz5fMRsMr8lDc/view?usp=drive_link', 
  'prabhjots5498@gmail.com.pdf': 'https://drive.google.com/file/d/1uI1gHLSjsT4Yc3iRfbIeEOdsML4Ydq7w/view?usp=drive_link', 
  'pradhyumshivhare8@gmail.com.pdf': 'https://drive.google.com/file/d/1vqVboRzkn5xnZYhiJ8FkG9pKe2e8Tsqc/view?usp=drive_link', 
  'pramod20chandra@gmail.com.pdf': 'https://drive.google.com/file/d/1he32gpa4TLBOkKMSvP0IX3Ys16yzEMVI/view?usp=drive_link', 
  'pranaya484@gmail.com.pdf': 'https://drive.google.com/file/d/1EYBYEe5TWh33jmyKSOaLRbK8Lg8pw4RQ/view?usp=drive_link', 
  'pranjalisharma6543@gmail.com.pdf': 'https://drive.google.com/file/d/1Yl32B4sWIzsa7IcfLZ3pQrWpcDFGPEqy/view?usp=drive_link', 
  'pranjalyaadav19@gmail.com.pdf': 'https://drive.google.com/file/d/1b_T-UAQA4fHG9bDmaKd2gICMu75gM9el/view?usp=drive_link', 
  'prashantkashyap2707@gmail.com.pdf': 'https://drive.google.com/file/d/1QHnyt6TI2vvXZWMNTlS4FGTNevy28_TP/view?usp=drive_link', 
  'prathamarora333@gmail.com.pdf': 'https://drive.google.com/file/d/1XFWg9AANRayqjx7P6YoVobL4EgW9bRe4/view?usp=drive_link', 
  'pratyushmathur2004@gmail.com.pdf': 'https://drive.google.com/file/d/1Q3VndAeycdbbkh3eAEsltko6vHs2EjYV/view?usp=drive_link', 
  'privyanshu26@gmail.com.pdf': 'https://drive.google.com/file/d/1XPmAIjw7l5CFXn-9ITrLfbn59dBSDNPt/view?usp=drive_link', 
  'priyansh13aug@gmail.com.pdf': 'https://drive.google.com/file/d/1qlwuhL0d93Ivff9rM3Yc0q-qVDprTcsr/view?usp=drive_link', 
  'priyanshu.22390@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1jPsSN7Bp9QHPrBiKNJGIfsYgB2XfXKdC/view?usp=drive_link', 
  'priyanshukumar01658@gmail.com.pdf': 'https://drive.google.com/file/d/107llp4HBg9Shm5R9z5lZyIs75uaR7AIA/view?usp=drive_link', 
  'pulkit.23290@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/11nlnNFqNavd3JBfV-x83kc-elKWsGyp-/view?usp=drive_link', 
  'py823211@gmail.com.pdf': 'https://drive.google.com/file/d/1oQpIwg0k7AhMHkHtRxMQvRQe4dWE1lOG/view?usp=drive_link', 
  'raagul.s.eee.2021@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1N2WZ0s3da1ntJb9i7_0BvYMwoKOW4aU-/view?usp=drive_link', 
  'radhikalal528@gmail.com.pdf': 'https://drive.google.com/file/d/1cyjrgKPKZPKJ6sP1UT9-F4kf7RK6jEK8/view?usp=drive_link', 
  'radhmani.c.agri.2021@snsct.org.pdf': 'https://drive.google.com/file/d/1lb4f_A5Q_6x3j0lcZRlCbDTJnGCHFgxZ/view?usp=drive_link', 
  'rahulchoudhary.sk@gmail.com.pdf': 'https://drive.google.com/file/d/1WdbvQ-kysu4irSsSWFDdGPgjSk_rRIu8/view?usp=drive_link', 
  'rahulthealex1200@gmail.com.pdf': 'https://drive.google.com/file/d/1tCdiRKSHc9oeekyjv0qD97qk66PrOOYH/view?usp=drive_link', 
  'rainamohan024@gmail.com.pdf': 'https://drive.google.com/file/d/1FtQKo8TVSWykFnulCWq6Z-psxW5zQksq/view?usp=drive_link', 
  'rajatbhardwaj54088@gmail.com.pdf': 'https://drive.google.com/file/d/1D0XddnwpnyTmMjWlgfn8Yh1Qiy-Yt4wv/view?usp=drive_link', 
  'rajeshbaranwal9044@gmail.com.pdf': 'https://drive.google.com/file/d/1rUnUULTx_RYk_DitD84OY-Y_CUZLL-fb/view?usp=drive_link', 
  'rajputyash072@gmail.com.pdf': 'https://drive.google.com/file/d/1P16S5NgitcsKLjpS08R97dv5xqX3iQNm/view?usp=drive_link', 
  'rajsingh76554@gmail.com.pdf': 'https://drive.google.com/file/d/1c9MmSPwr-EH7fKo0aXYstgAlYZuhGs03/view?usp=drive_link', 
  'ramesh.m.auto.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1jg0g38qfDOilHwiPJuneOb1aPV_fooYc/view?usp=drive_link', 
  'ranjith.v.cst.2021@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1b08y_094BCO16srcLEwgdZwws23dQW7k/view?usp=drive_link', 
  'rashimittal8898@gmail.com.pdf': 'https://drive.google.com/file/d/196Y__Uwr4enTDdTbqVpHPo99NqeovSR-/view?usp=drive_link', 
  'reachkhushi25@gmail.com.pdf': 'https://drive.google.com/file/d/1vxJenHUzwL47OxNFEIxN79MOIUi-FXwo/view?usp=drive_link', 
  'rohan.23360@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1hGUnQ3RZ2gT_Fn3OI7DchEnCkZ-uWxfK/view?usp=drive_link', 
  'royalboy0981@gmail.com.pdf': 'https://drive.google.com/file/d/1uTF9LJ5cqgdmC6V5jXR5jbbleMg4FtNI/view?usp=drive_link', 
  'rprohitpapnai@gmail.com.pdf': 'https://drive.google.com/file/d/1EWy6Tv2in8-CqRE1oM9pWQWGmO6bjRnm/view?usp=drive_link', 
  's9554988920@gmail.com.pdf': 'https://drive.google.com/file/d/1U04-WWK75Z1uJYunJ5V9kUANw0SDb53l/view?usp=drive_link', 
  'sachingurjar9711@gmail.com.pdf': 'https://drive.google.com/file/d/1-I3-wOI6dmeIgOqhOirOUCJgtUHeRt0X/view?usp=drive_link', 
  'sagarjha2004@gmail.com.pdf': 'https://drive.google.com/file/d/152c8hJUUQiD9EYFsiykc5wzidBHXRRO3/view?usp=drive_link', 
  'sahanianisha1108@gmail.com.pdf': 'https://drive.google.com/file/d/1InnJOS5YnJnussxYP2e7cdsdIHhlOAVG/view?usp=drive_link', 
  'sahil.25092004@gmail.com.pdf': 'https://drive.google.com/file/d/1m1lxstWMK7sc0dbLxkdbwVs34u03Oq2A/view?usp=drive_link', 
  'sahilhusain790@gmail.com.pdf': 'https://drive.google.com/file/d/1Jua3QV3_A_AS1AYO6CbomxIU4yV6ORIH/view?usp=drive_link', 
  'sajalmishra2005@gmail.com.pdf': 'https://drive.google.com/file/d/1NwEAknVDT5Ghexmtzb4y8UR-aqPC6QpL/view?usp=drive_link', 
  'samson.a.auto.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1mJZgXr0o6LYd_Vz_17CZLXfZC_Q6AmXG/view?usp=drive_link', 
  'sandeepyadav004343@gmail.com.pdf': 'https://drive.google.com/file/d/1EUObQzlNxQY07Zmf1gaiAvrz4Svbbiin/view?usp=drive_link', 
  'sandradheeraj05@gmail.com.pdf': 'https://drive.google.com/file/d/1fsiEVBsD0NlISHxpcMRScdSP2hvXbiWa/view?usp=drive_link', 
  'sanjay.r.mct.2022@snsct.org.pdf': 'https://drive.google.com/file/d/1ruGImzVuIVUnOIg0J4ZLApBBER0a9cqr/view?usp=drive_link', 
  'saterjeetray04@gmail.com.pdf': 'https://drive.google.com/file/d/18zGByWYeOp2FHd5iajh1lRLuBLRzhGE-/view?usp=drive_link', 
  'satyarshishukla123@gmail.com.pdf': 'https://drive.google.com/file/d/1EfboBemgpazvNc6k5iahL45s5cFn1srx/view?usp=drive_link', 
  'satyendrasinghfzd184@gmail.com.pdf': 'https://drive.google.com/file/d/1pmEHE4hK6-HDzdL-B7_VU-JVhwKTGMwl/view?usp=drive_link', 
  'saumya04rawat@gmail.com.pdf': 'https://drive.google.com/file/d/1-5ldVVgW7V_r8OauxJw3xiCHKMN0Q1_o/view?usp=drive_link', 
  'seyjalkhatri9876@gmail.com.pdf': 'https://drive.google.com/file/d/13NgyZUNrIDOf_aahYoclHUnq7KJa0RdK/view?usp=drive_link', 
  'shardik247@gmail.com.pdf': 'https://drive.google.com/file/d/1Q5kNS4BHctx5ZEMzm4uJha1P2tbfXGry/view?usp=drive_link', 
  'sharmarishi3718@gmail.com.pdf': 'https://drive.google.com/file/d/1h3lRZXzVNmKqnLcyTt8GI2M1BxI--sPl/view?usp=drive_link', 
  'sharmashantanusharma16@gmail.com.pdf': 'https://drive.google.com/file/d/1kaHpBrDwgv1fSfuLdxI1n7RSnOevMyPr/view?usp=drive_link', 
  'shasha12345nk@gmail.com.pdf': 'https://drive.google.com/file/d/1WLmqy5FsC06A3wSQmVyoJJgAttT3MRhj/view?usp=drive_link', 
  'shivanig6263@gmail.com.pdf': 'https://drive.google.com/file/d/1w6J7EnNjEwW-1BUhpyGLRO9o6aPk5NQR/view?usp=drive_link', 
  'shivpandey5677@gmail.com.pdf': 'https://drive.google.com/file/d/1n92ch0XyWHYO6phVb9Tt2EhH5o_PrQtX/view?usp=drive_link', 
  'shokeenjigyasa@gmail.com.pdf': 'https://drive.google.com/file/d/14T9akH0MXoD1Bsqtb7xOJ8TMapdVGgFO/view?usp=drive_link', 
  'shoubhikroy8@gmail.com.pdf': 'https://drive.google.com/file/d/1fciXL9FVUf91EcPahiBs0_RugYwfCTZn/view?usp=drive_link', 
  'shouryabhardwaj19@gmail.com.pdf': 'https://drive.google.com/file/d/1By756rjZTJYtGbC1wznIR4Rv3rc2rDhK/view?usp=drive_link', 
  'shreyaag2003@gmail.com.pdf': 'https://drive.google.com/file/d/1AndqC0FLtqE1HU4J0w7gwIhx1SWVOKRy/view?usp=drive_link', 
  'silambar.s.aero.2021@snsct.org.pdf': 'https://drive.google.com/file/d/15eajIQiQG5HUOOZZalbrCum0CgHZiZkG/view?usp=drive_link', 
  'sindhwanilove@gmail.com.pdf': 'https://drive.google.com/file/d/1DWqTcVSwwpmVrdTP9uXOZ2c30gO5DoMG/view?usp=drive_link', 
  'singhsagarv1@gmail.com.pdf': 'https://drive.google.com/file/d/1o-H1sHoI_2xHzZS63LhWfY9ZkW0y3_EC/view?usp=drive_link', 
  'sirohikhushi04@gmail.com.pdf': 'https://drive.google.com/file/d/1uZo9PEjdGs7ENLtwKaljeKKTr1fwuYyJ/view?usp=drive_link', 
  'sivanan.e.civil.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1LbiF4yGjBkuga9XnqBS-XdYweSlfwtly/view?usp=drive_link', 
  'sm6720@dseu.ac.in.pdf': 'https://drive.google.com/file/d/1vO6yTLAIdcsOugLRwMnuPs0xh8EqokLK/view?usp=drive_link', 
  'sneha.pandey1208@gmail.com.pdf': 'https://drive.google.com/file/d/1jXPFiSkfKC4EadFfV8e86e3t2-uQYNdF/view?usp=drive_link', 
  'snehalsrivastava27@gmail.com.pdf': 'https://drive.google.com/file/d/1XMc-pJqhjmvzTYhmhpcozGj0JyYqMT6l/view?usp=drive_link', 
  'sonalinjoy@gmail.com.pdf': 'https://drive.google.com/file/d/1_PrANtUm4ZeVOzss3mAVPDTvlEuHdLuu/view?usp=drive_link', 
  'sparsh.22416@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/13QDSiGvfW1Holg839fQ8Wm6fwh_mGaGk/view?usp=drive_link', 
  'srijan1248@gmail.com.pdf': 'https://drive.google.com/file/d/1yZ4RjhcV1LEwE8gEx_psyTkCT6P-wHnq/view?usp=drive_link', 
  'subhrajeetkhakhlari@gmail.com.pdf': 'https://drive.google.com/file/d/1GJLj16NHK_J8XblUDHXjb-wLaJVnIrcB/view?usp=drive_link', 
  'sujalgupta0317@gmail.com.pdf': 'https://drive.google.com/file/d/1irXgCsDsgSHmVj7yihOowVov5QF0hZWO/view?usp=drive_link', 
  'sujalgupta1412@gmail.com.pdf': 'https://drive.google.com/file/d/1yRCZyqylWceVWQt8SSqG2ctg-NXa2Z_b/view?usp=drive_link', 
  'sy843123sachin@gmail.com.pdf': 'https://drive.google.com/file/d/1SWcalHE9eiBUP4cg_TJd3DoUIbPv5VZY/view?usp=drive_link', 
  'syedakramahahmadfaizi_it21a10_53@dtu.ac.in.pdf': 'https://drive.google.com/file/d/1mnYnxp0I301J9F6SOdwXilthGB1TYbPk/view?usp=drive_link', 
  'tanish14082004@gmail.com.pdf': 'https://drive.google.com/file/d/1mdWIppMkHqd2UtvHVOljNWpydBVCuUnO/view?usp=drive_link', 
  'tayush139@gmail.com.pdf': 'https://drive.google.com/file/d/1C7hncOUSwPYZLFjscAdCCwPw9wUFzsO_/view?usp=drive_link', 
  'teekshadtu2021@gmail.com.pdf': 'https://drive.google.com/file/d/1p5nPz-vC8ezcQaGC-hEN_xGE9KVN4Iz8/view?usp=drive_link', 
  'thakuraman3719@gmail.com.pdf': 'https://drive.google.com/file/d/1S7C3AMqlGQPDVYzy-fXeOF5ssOAmvJNJ/view?usp=drive_link', 
  'thisisrahul2025@gmail.com.pdf': 'https://drive.google.com/file/d/1NFgs5hjSGUjIniA93vg5JCCTzNfbwhvA/view?usp=drive_link', 
  'thowfiq.s.civil.2020@snsct.org.pdf': 'https://drive.google.com/file/d/16YKcMe2xTRYc8i1zhdfFJNcrahhmN59c/view?usp=drive_link', 
  'tmnabeel30@gmail.com.pdf': 'https://drive.google.com/file/d/1PhZacpWHQ_f3uC5FRWoZCjquDnxTub4Z/view?usp=drive_link', 
  'tripathiyuvraj56@gmail.com.pdf': 'https://drive.google.com/file/d/1r2TlCeSj6QiYcf3Lr9np0flGVxf0eSqb/view?usp=drive_link', 
  'tusharg1504@gmail.com.pdf': 'https://drive.google.com/file/d/16RLkjU7V2_ee1jaT8CJAFTifCsuE_gRZ/view?usp=drive_link', 
  'tushikarawat474@gmail.com.pdf': 'https://drive.google.com/file/d/1ZxpPoRQR1ZYLhxY6Hmx8HAk25KLaBaND/view?usp=drive_link', 
  'tyagiatharva04@gmail.com.pdf': 'https://drive.google.com/file/d/1s3ybe0yTvjEr9lS7FBDw7vAV2boOjcw7/view?usp=drive_link', 
  'udhaya.s.eee.2020@snsct.org.pdf': 'https://drive.google.com/file/d/1M2o1gQOLUt0WwgTNPy8jhBwQsMXJW6ms/view?usp=drive_link', 
  'urvashikush14@gmail.com.pdf': 'https://drive.google.com/file/d/1frwW-F_pHkoE-Af88m5tcmQYZLxXzNCN/view?usp=drive_link', 
  'utsavkaushalblog10@gmail.com.pdf': 'https://drive.google.com/file/d/1-GkSNRwTxJShbH_y11TI86pnerpgWhcz/view?usp=drive_link', 
  'vaibhav.22427@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1cNE0CGe4r3VG4h_Q7GOtvJB5VFmKgTnQ/view?usp=drive_link', 
  'vanshajsood2005@gmail.com.pdf': 'https://drive.google.com/file/d/1DaBzqt4NtYt4lK2qfE5ptJaeFQHJN0l2/view?usp=drive_link', 
  'vanshikaagrawal035@gmail.com.pdf': 'https://drive.google.com/file/d/1KmQv_4RxcD15lotUVkLvO00zAoxdQa6b/view?usp=drive_link', 
  'varunjaiswalvv@gmail.com.pdf': 'https://drive.google.com/file/d/1yesIJ6-7oBsMNLPlnkllPjjB1kftNenr/view?usp=drive_link', 
  'vasudevanv304@gmail.com.pdf': 'https://drive.google.com/file/d/1I3bJLYLHnrJ7ABqhDQiduWwLn8rU_4bF/view?usp=drive_link', 
  'vg8383404@gmail.com.pdf': 'https://drive.google.com/file/d/1Hq5MBYvWTK9_j-WZWDbD1G2mfI2g41w_/view?usp=drive_link', 
  'vickykumar1552002@gmail.com.pdf': 'https://drive.google.com/file/d/185zd0w5C58-4_wWgWFVaaeSGqkx2mnj_/view?usp=drive_link', 
  'vidit.msit@gmail.com.pdf': 'https://drive.google.com/file/d/1WCnpD3_7wweWcknUjlABkeNrdT2fH74X/view?usp=drive_link', 
  'viditprajapati18@gmail.com.pdf': 'https://drive.google.com/file/d/1_fs7wKuuIpIVcQkuNP8QH1_z6es6Bgnc/view?usp=drive_link', 
  'vighnesh2504@gmail.com.pdf': 'https://drive.google.com/file/d/1PfbtUFUbF8-kHcp7ymNRJJ7ab-iJcHmx/view?usp=drive_link', 
  'vikas.23453@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/15K9p09kAK4wCgtSmHyfRoBsKy9RG7cE9/view?usp=drive_link', 
  'vikasofficial2211@gmail.com.pdf': 'https://drive.google.com/file/d/1Kh26HqlQvRzZd54xQx5lFNVubG1CvyEC/view?usp=drive_link', 
  'vineet.22566@sscbs.du.ac.in.pdf': 'https://drive.google.com/file/d/1FguxUc7mdQSa1VmOXcMlH_6pNAq1L5sB/view?usp=drive_link', 
  'viswesh.b.cst@snsce.ac.in.pdf': 'https://drive.google.com/file/d/1h4jOeU853uhTgFBvaI7B8UL_iNAHGV4G/view?usp=drive_link', 
  'wizardmishramadhav@gmail.com.pdf': 'https://drive.google.com/file/d/1A5bKAcSGZ4PYwTHwaAfWXnzCHtFOh-XV/view?usp=drive_link', 
  'yash2yk2@gmail.com.pdf': 'https://drive.google.com/file/d/1beIXDGOxaWf3U8M2gUS6OWRKS3FTO4gV/view?usp=drive_link', 
  'yocktherock1990@gmail.com.pdf': 'https://drive.google.com/file/d/1CzsdBKE7tACOH4LJVhrIJx1rObdMO8QA/view?usp=drive_link', 
  'yogeshwar.p.cst@snsce.ac.in.pdf': 'https://drive.google.com/file/d/18tV8cmboxfWC_SD20D5Q43v6CbvfxRYW/view?usp=drive_link', 
  'yoyashmeet@gmail.com.pdf': 'https://drive.google.com/file/d/1p0b4_1eTF9UBbkfxUtTlMV5560nABIe5/view?usp=drive_link', 
  'ywiwkskdkdj@gamil.vom.pdf': 'https://drive.google.com/file/d/1oZ-elmwpzuQpneBPdE--Rx12Hdi973yN/view?usp=drive_link'
}

  function updateCount(){
    if(down < 20){
      return;
    }
    set(ref(db, 'DownloadsCount'), {
      DownloadsCount: down+1
    });
  }

  useEffect(() => {
    const downloadCount = ref(db, 'DownloadsCount/DownloadsCount');
    onValue(downloadCount, (snapshot) => {
      const data = snapshot.val();
      setDown(data);
    });
    console.log("Data Fetched")
  }, []);


  function searchResult(){
    if(Object.keys(data).includes(email.toLowerCase()+'.pdf')){
      const url = data[email.toLowerCase()+'.pdf'];
      toast.success('Certificate Found! Here is your Certificate!')
      setTimeout(()=>{window.open(url, '_blank');setEmail("")}, 2000)
      updateCount();
    }else{
      toast.error('No Certificate Associated with this E-mail')
    }
  }

  return (
    <div className='h-screen w-full'>
      <Toaster richColors={true}/>
      <div className='h-[10vh] w-full bg-blue-900 justify-center flex items-center md:text-5xl text-3xl font-semibold text-white'>
        IdeaGem Certificate
      </div>
      <section className='h-[80vh] w-full flex flex-col md:flex-row'>
        <div className='md:w-[50vw] w-full h-full justify-center items-center flex flex-col '>
          <img alt='logo' src={logo}/>
          <p className=' text-xl font-medium'>Total Certificates Downloaded : {down}</p>
        </div>
        <div className='md:w-[50vw] w-full h-full justify-center items-center flex flex-col gap-4'>
          <input className=' py-2 px-4 w-1/2 border-2 border-blue-900 rounded-md shadow-lg' type='email' placeholder='Enter registered e-mail' value={email} onChange={(e)=>setEmail(e.target.value)} />
          <button type='submit' onClick={searchResult} className='bg-blue-900 hover:bg-slate-600 shadow-md rounded-md py-2 px-5 font-medium text-white'>Find</button>
        </div>
      </section>
      <div className='h-[10vh] w-full border-t-2 bg-blue-900 text-white justify-center items-center font-semibold text-2xl flex flex-col '>
        <a href='https://www.linkedin.com/in/yadavroshni/'  rel="noreferrer" target='_blank'><h1>Developed by Roshni Yadav</h1></a>
        <h1 className='text-sm'>ACM SSCBS 2024-25</h1>
      </div>
    </div>
  );
}

export default App;
